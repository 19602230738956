#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;

  .page {
    display: flex;
    flex-direction: column;
    min-height: 100%;
    flex: 1;
    &-content {
      flex: 1;
    }
  }

  .search-result {
    width: 90%;
    max-width: none !important;
  }
}
