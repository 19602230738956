.home {
  .slider {
    background: $primary-color-darker;
    border-bottom: 4px solid $primary-color-darker;
    margin-bottom: 50px;

    &-background {
      color: #fff;
      width: 100%;
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: center top;
      background-size: cover;
      background-image: url('../../assets/bg.jpg');
    }

    &-content {
      padding: 3vw;
      text-align: right;
    }

    &-title {
      font-size: #{"clamp(2rem, 2rem + 3vw, 6rem)"};
    }

    &-text {
      font-size: #{"clamp(1rem, 1rem + 3vw, 2rem)"};
      color: #eee;
    }

    @media (max-width: 768px) {
      &-content {
        padding: 4em 3vw 1em;
        text-align: center;
      }
    }
  }

  .feature {
    padding-left: 90px;
    padding-bottom: 40px;

    h3 {
      margin: 0;
    }

    .icon-frame {
      position: absolute;
      top: 0;
      left: 10px;
      padding: 0;
      margin: 0;
      width: 60px;
      height: 60px;
      line-height: 55px;
      text-align: center;
      font-size: 26px;
      color: $primary-color;
      border: 3px solid $primary-color;
      @include border-radius(50%);
    }
  }

  .world-chart {
    &-wrapper {
      @include transition(height ease 0.3s);
      width: 100%;
      height: 0.55vw;
      overflow: hidden;
      border-top: 3px solid $primary-color-darker;
      border-bottom: 3px solid $primary-color-darker;
    }
  }

  .news-item {
    .title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 20px 0 0 0;
      color: $primary-color;
      font-size: 22px;
      font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    .date {
      color: #aaa;
      font-weight: normal;
      font-size: 10px;
      margin-right: 7px;
      display: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .text-overflow-container {
      overflow: hidden;
      position: relative;
    }

    .text {
      font-size: 13px;
    }
  }
}
