.cluster-marker {
  color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

$markerWidth: 49px;
$markerHeight: 64px;

$originX: $markerWidth * 0.5;
$originY: $markerHeight;

.marker {
  background-image: url('../../assets/mapIcon.svg');
  position: absolute;
  cursor: pointer;
  width: $markerWidth;
  height: $markerHeight;
  top: -$originY;
  left: -$originX;
  transform-origin: $originX $originY;
  margin: 0;
  padding: 0;
  transform: translate3D(0, 0, 0) scale(0.3, 0.3);
}

.popup {
  background: #fff;
  color: #1978c8;
  border-radius: 10px;
  padding: 10px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  left: -40px;
  bottom: 15px;
  position: absolute;

  &-layout {
    cursor: pointer;
    white-space: nowrap;
  }
}

.courses-table {
  @include user-select(none);

  &-row {
    cursor: pointer;
    color: #1978c8;
  }

  th,td {
    text-align: center;
    
    &.column-id {
      font-size: 0.8rem;
      text-align: left;      
    }
    &.column-name {
      text-align: left;      
    }
    &.column-updated {
      text-align: right;      
    }
  }
}

.sort-column {
  cursor: pointer;

  &:hover{
    color: $link-color-hover;
  }

  &--order {
    display: inline-flex;
    flex-direction: row;
    max-width: 30px;
    margin-left: 5px;
    vertical-align: middle;

    &--carets {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;

      & > * {
        margin-top: -2px;
        line-height: 10px;
        font-size: 10px;
      }
    }

    &--priority {
      flex-grow: 1;
      flex-shrink: 0;
      flex-basis: 0;
      vertical-align: middle;
      margin-left: 2px;
      font-size: 10px;
      color: grey;
    }
  }
}

.sticky-panel-bottom {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 1em 1em 0.5em 1em;
  background-color: #fff;
  border: 1px solid gray;
  border-radius: 0.4em;
  z-index: 1;
}