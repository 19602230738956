// $import 'prefixer';

$primary-color: #08c;
$primary-color-dark: darken($primary-color, 10%);
$primary-color-darker: darken($primary-color, 20%);
$primary-color-darkest: darken($primary-color, 30%);
$primary-color-light: lighten($primary-color, 10%);
$primary-color-lighter: lighten($primary-color, 20%);

$color-on-primary: #ffffff;

$link-color: #428bca;
$link-color-hover: #2a6496;

$background-color: #dddddd;
$background-color-light: #ffffff;

$navbar-color: $primary-color;
$navbar-font-color: #ffffff;

$primary-font-color: #666666;

$panel-color: $primary-color;
$panel-font-color: #ffffff;
$panel-font-color-2: #dddddd;

$color-error: #ff0000;
$color-ok: #00aa00;

$spinner-color: $panel-font-color;
$spinner-size: 8px;

// TODO Clenaup duplicate definitions here
$hover-color: lighten($primary-color, 50%);

$padding: 4px;
$padding-large: $padding * 2;

$default-size: 48px;
$default-padded-size: $default-size - ($padding * 2);

$header-font-size: 32px;

$albatross: #f8ff74;
$eagle: #4cd2db;
$birdie: #4cdbb7;
$even: #ecedf0;
$bogey: #ff7fb0;
$doublebogey: #ff3a85;
$triplebogey: #ff0062;