.game {
    .scorecard {
        border-collapse: separate;
        border-spacing: 10px;

        &-overflow {
            overflow-x: scroll;
        }
    
        &-header {
            text-align: center;
            text-transform: capitalize;
            &-top {
                text-align: right;
                padding: 4px 10px;
            }
            &-middle {
                text-align: right;
                padding: 4px 10px;
            }
            &-bottom {
                &-names {
                    text-align: left;
                    padding: 4px 10px;
                }
                &-distance {
                    text-align: right;
                    padding: 4px 10px;
                }
            }
        }
    
        &-row {
            // border: 1px solid $primary-color;
            &-name {
                min-width: 300px;
                height: 50px;
                padding-left: 10px;
                font-weight: bold;
            }
            &-par {
                text-align: center;
                font-weight: bold;
            }
            &-cell {
                border: 1px solid transparent;
                border-radius: 5px;
                text-align: center;
                font-weight: bold;
                &.albatross {
                    background-color: $albatross;
                    border-color: $albatross;
                }
                &.eagle {
                    background-color: $eagle;
                    border-color: $eagle;
                }
                &.birdie {
                    background-color: $birdie;
                    border-color: $birdie;
                }
                &.even {
                    background-color: $even;
                    border-color: $even;
                }
                &.bogey {
                    background-color: $bogey;
                    border-color: $bogey;
                }
                &.doublebogey {
                    background-color: $doublebogey;
                    border-color: $doublebogey;
                }
                &.triplebogey {
                    background-color: $triplebogey;
                    border-color: $triplebogey;
                }
            }
        }
    
        &-holeNumber {
            font-size: 18px;
            font-weight: bold;
        }
        &-holePar {
            $size: 30px;
            color: $color-on-primary;
            background-color: $primary-color;
            border-radius: 50%;
            font-size: 90%;
            width: $size;
            height: $size;
            line-height: $size;
            display: inline-block;
            margin: 6px 20px;
        }
        &-holeDistance {
            font-size: 14px;
            cursor: pointer;
            min-width: 40px;
        }
    }

    td.scorecard-out,
    td.scorecard-in {
      background-color: $background-color;
    }
  
    tbody tr:not(:first-child) > td.scorecard-row-name,
    tbody tr:not(:first-child) > td.scorecard-row-par {
      border-top: 1px solid lightgray;
    }
}