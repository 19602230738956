.admin {
    .is-active {
        // color: black;
    }
    .is-inactive {
        color: lightgray;
    }
    .is-private {
        color: lightpink;
    }
    .is-public {
        // color: black;
    }
    .is-unique {
        // color: lightcoral;
    }
    .is-non-unique {
        opacity: 0.4;
    }
    .is-small {
        // color: lightcoral;
    }
    .is-large {
        // color: lightcoral;
    }
    .has-location {
        // color: lightcoral;
    }
    .has-no-location {
        color: darkorchid;
    }
    .has-wrong-hole-count {
        color: darkred;
    }
    .is-zombie {
        color: darkolivegreen;
    }
    .has-wrong-country {
        color: darkred;
    }

    &.table {
        white-space: nowrap;
    }

    td.info {
        font-size: 9px;
        max-width: 150px;
        word-wrap: break-word;
        white-space: initial;
    }
    .diff {
        color: darkorange;
        font-weight: bold;

        &.diff-par {
            color: red;
        }
    }
    .group-buttons {
        display: flex;
        flex-direction: row;
        gap: 10px;
        margin-bottom: 5px;
        &>* {
            flex-grow: 1;
            flex-shrink: 0;
            flex-basis: 0;
        }
    }
}