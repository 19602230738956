@mixin user-select($params) {
  user-select: $params;
  -o-user-select: $params;
  -moz-user-select: $params;
  -khtml-user-select: $params;
  -webkit-user-select: $params;
  -webkit-touch-callout: none;
}

@mixin placeholder($params) {
  &::-webkit-input-placeholder {
    // WebKit browsers
    color: $params;
  }
  &:-moz-placeholder {
    // Mozilla Firefox 4 to 18
    color: $params;
    opacity: 1;
  }
  &::-moz-placeholder {
    // Mozilla Firefox 19+
    color: $params;
    opacity: 1;
  }
  &:-ms-input-placeholder {
    // Internet Explorer 10+
    color: $params;
  }
}

// Transitions

@mixin transition($args: 200ms) {
  -webkit-transition: $args;
  -moz-transition: $args;
  -o-transition: $args;
  -ms-transition: $args;
  transition: $args;
}
@mixin transition-delay($delay: 0) {
  -webkit-transition-delay: $delay;
  -moz-transition-delay: $delay;
  -o-transition-delay: $delay;
  -ms-transition-delay: $delay;
  transition-delay: $delay;
}
@mixin transition-duration($duration: 200ms) {
  -webkit-transition-duration: $duration;
  -moz-transition-duration: $duration;
  -o-transition-duration: $duration;
  -ms-transition-duration: $duration;
  transition-duration: $duration;
}
@mixin transition-property($property: all) {
  -webkit-transition-property: $property;
  -moz-transition-property: $property;
  -o-transition-property: $property;
  -ms-transition-property: $property;
  transition-property: $property;
}
@mixin transition-timing-function($function: ease) {
  -webkit-transition-timing-function: $function;
  -moz-transition-timing-function: $function;
  -o-transition-timing-function: $function;
  -ms-transition-timing-function: $function;
  transition-timing-function: $function;
}
