$player-size: 48px;

.player {
  &-thumb {
    width: $player-size;
    height: $player-size;
    object-fit: cover;
    border-radius: 50%;
  }
  &-initials {
    width: $player-size;
    height: $player-size;
    line-height: ($player-size - 1);
    text-align: center;
    background-color: $primary-color;
    border-radius: 50%;
    color: $color-on-primary;
    vertical-align: middle;
    font-weight: bold;
    display: inline-block;
  }
}

table .players-table {
  &-row {
  }
  &-col {
    vertical-align: middle;
    &-thumb {
      text-align: center;
    }
    &-name {
    }
    &-info {
    }
    &-email {
    }
    &-phone {
    }
  }
}
