.printable-scorecard,
.course {
  .course-details {
    width: 100%;
  }

  .scorecard {
    border-collapse: separate;
    border-spacing: 10px;

    &-overflow {
      overflow-x: scroll;
    }

    &-header {
      text-align: center;
      text-transform: capitalize;
      &-top {
        text-align: right;
        padding: 4px 10px;
      }
      &-middle {
        text-align: right;
        padding: 4px 10px;
      }
      &-bottom {
        &-names {
          text-align: left;
          padding: 4px 10px;
        }
        &-distance {
          text-align: right;
          padding: 4px 10px;
        }
      }
    }

    &-row {
      border: 1px solid $primary-color;
      &-name {
        min-width: 300px;
        height: 50px;
      }
      &-cell {
        border: 1px solid lightgray;
        border-radius: 5px;
      }
    }

    &-holeNumber {
      font-size: 18px;
      font-weight: bold;
    }
    &-holePar {
      $size: 30px;
      color: $color-on-primary;
      background-color: $primary-color;
      border-radius: 50%;
      font-size: 90%;
      width: $size;
      height: $size;
      line-height: $size;
      display: inline-block;
      margin: 6px 20px;
    }
    &-holeDistance {
      font-size: 14px;
      cursor: pointer;
      min-width: 40px;
    }

    td.scorecard-out,
    td.scorecard-in {
      background-color: $background-color;
    }
  
    tbody tr:not(:first-child) > td:first-child {
      border-top: 1px solid lightgray;
    }
  }
}

.printable-scorecard {
  .scorecard {
    border-collapse: collapse;

    &-header {
      th:not(.scorecard-header-top):not(.scorecard-header-middle):not(.scorecard-header-bottom) {
        border-left: 1px solid grey;
        border-right: 1px solid grey;
      }
      tr:first-child th:not(:first-child) {
        border-top: 1px solid grey;
      }
    }
    
    &-row {
      border: 1px solid grey;

      &-cell {
        border: 1px solid grey;
      }
    }

    &-holePar {
      $size: 1cm;
      color: black;
      background-color: transparent;
    }

    &-out,
    &-in {
      background-color: $background-color;
    }
  
    tbody tr:not(:first-child) > td:first-child {
      border-top: 1px solid grey;
    }
  }

  .scorecard-overflow {
    overflow-x: unset;
  }
}

@media print {
  @page {
    size: landscape;
  }

  .printable-scorecard {
    // transform-origin: top left;
    // transform: scale(0.5);

    .scorecard {
      page-break-inside: avoid;
      max-width: 100%;

      &-header {
        &-top {
          padding: 0.3em 0.7em;
        }
        &-middle {
          padding: 0.3em 0.7em;
        }
        &-bottom {
          &-names {
            padding: 0.3em 0.7em;
          }
          &-distance {
            padding: 0.3em 0.7em;
          }
        }
      }

      &-row {
        &-name {
          min-width: auto;
          width: 5cm;
          height: 50px;
        }
      }

      &-holeNumber {
        font-size: 1.2em;
      }
      &-holePar {
        $size: 1cm;
        color: black;
        font-size: 90%;
        width: $size;
        height: $size;
        line-height: $size;
        margin: 0.3em 0.7em;
      }
      &-holeDistance {
        font-size: 0.8em;
        min-width: auto;
        width: 40px;
      }
    }
  }
}