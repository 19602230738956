.menu {
  .user-photo-small {
    width: 38px;
    height: 38px;
    border-radius: 50%;
  }

  .user-photo-large {
    border-radius: 50%;
    margin: 20px auto;
  }

  .user-name {
    color: #444;
  }

  .user-email {
    color: #888;
  }

  .user-info {
    padding: 0.25rem 1.5rem;
    margin-bottom: 20px;
    text-align: center;
    white-space: nowrap;
    width: 100%;
  }
}
