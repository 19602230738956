.course-overview {
    margin-top: 2em;
    margin-bottom: 2em;
    border-top: 1px solid lightgray;
    overflow: auto;
    
    cursor: pointer;

    &:hover {
        background-color: darken(#FFFFFF, 5%);
    }

    &--details {
        font-size: 0.8rem;
        margin-bottom: 0.5rem;
    }
    &--description {
        font-size: 0.7rem;
        margin-bottom: 0.5rem;
    }
    &--table {
        th {
            font-size: 0.5rem;        
            font-weight: 400;
            text-transform: uppercase;
            text-align: end;
        }

        td {
            text-align: center;
        }

        td:not(:nth-child(1)) {
            padding-left: 1rem;
            padding-right: 1rem;
        }
    }

    &--hole {
        font-weight: bold;
        font-size: 1rem;
    }

    &--distance {
        font-size: 0.8rem;
    }

    &--par {
        font-size: 1rem;
    }
}