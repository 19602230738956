.footer {
  @include gradient-y($background-color-light, $background-color);
  margin: 100px 0 0 0;
  padding: 30px 0;

  .copy {
    text-align: center;
    font-size: 10px;
    margin-bottom: 100px;

    .spacer {
      height: 1px;
      // background: #fff;
      margin: 20px 0;
    }
  }
}
