.faq {
  &-question {
    font-weight: bold;
    display: block;
    cursor: pointer;
  }
  &-answer {
    display: block;
  }
}
