html,
body {
  font-family: 'Montserrat', sans-serif;
  height: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

h1 {
  padding-top: 40px;
}

h2 {
  padding-top: 30px;
}

h3 {
  padding-top: 20px;
}

h4 {
  padding-top: 10px;
}

h5 {
  padding-top: 6px;
}

h6 {
  padding-top: 4px;
}

.nowrap {
  white-space: nowrap;
}

